import { Component, OnInit, ViewChild, ElementRef, Output, Inject, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { MatPaginator, MatSort} from '@angular/material';
import { ProcessSummaryDataSource } from '../../services/process-summary.datasource';
import { ProcessService } from '../../services/process.service';
import { MatDialog} from '@angular/material';
import { Router } from '@angular/router';
import { YearService } from 'src/app/services/year.service';

export interface DialogData {
  animal: string;
  name: string;
}
@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent implements OnInit, AfterViewInit {
  displayedColumnsSummary: string[] = ['name', 'submitted', 'inProgress', 'total'];
  currentYear = new Date().getFullYear().toString()
  currentNIU='';
  currentYearInt = new Date().getFullYear()
  displayedYears = []
  dataSourceSummary: ProcessSummaryDataSource;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input', { static: false }) input: ElementRef;

  animal: string;
  name: string;
  constructor(private processService: ProcessService, private router: Router, private route: ActivatedRoute, public yearService: YearService, public dialog: MatDialog) {

  }
 
  cellClicked(element) {
  }

  ngOnInit() {
    this.yearService.getList("year").subscribe(val=>{
      val.forEach(element => {
        this.displayedYears.push(element.year)
      });
      if (val.length>0){
        this.currentYear = val[0].year
        this.dataSourceSummary.loadProcessSummary(this.currentYear);
      }
      
    });
    if (this.route.snapshot.paramMap.get('year')) {
      this.currentYear = this.route.snapshot.paramMap.get('year')
    }

    this.route
    .queryParams
    .subscribe(params => {
      // Defaults to 0 if no query param provided.
      if (params['niu']){
        this.currentNIU = params['niu']
      }
      
    });
    
    this.dataSourceSummary = new ProcessSummaryDataSource(this.processService)
  }

  goToStatDetails(row){
    this.router.navigate(['stat_details'], { queryParams: { dec_key: row.key, dec_name: row.name, dec_year:this.currentYear}});
  }

  handleSelection(event) {
    this.currentYear = event.value;
    this.dataSourceSummary.loadProcessSummary(this.currentYear);
  }

  ngAfterViewInit() {

    setTimeout(() => {
        this.dataSourceSummary.loadProcessSummary(this.currentYear);
    }, 0)
  }
}




