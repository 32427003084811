import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SharedService } from './sharedService';
import { map } from 'rxjs/operators';
import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})
export class TaxPayerService extends BaseService{

  constructor(public http: HttpClient, public sharedService: SharedService) { 
    super(http, sharedService);
  }

  getCurrentCompany(id): Observable<any> {
    return this.http.get(this.sharedService.apiUrl('tax-payers/niu')+id).pipe(
      map(res => {
        return res;
      })
    );
  }

}



