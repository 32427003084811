import { replaceValues } from 'src/app/entities/util';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { DialogData } from 'src/app/entities/dialogData';
import { SharedService } from 'src/app/services/sharedService';
import { Inject, EventEmitter } from '@angular/core';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { User } from 'src/app/entities/user';

export class DialogCommunicator {
  dialogEntity: any;

  updateEntityEmitter: EventEmitter<any> = new EventEmitter();
  dialog: MatDialog;
  constructor(
    public dialogRef: MatDialogRef<DialogCommunicator>,
    public sharedService: SharedService) {

  }


  setConfirmationDialog(dialog: MatDialog) {
    this.dialog = dialog
  }

  setEntity<T>(entity: T) {
    this.dialogEntity = entity
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
  onModifyStateClick(dialogText: any, action: any): void {
      const dialogRefConf = this.dialog.open(ConfirmationDialogComponent, {
        width: '500px',
        data: { text: "DIALOG." + dialogText, emitter: this.sharedService.updateEntity, obj: this.dialogEntity, action: action }
      });
      dialogRefConf.afterClosed().subscribe(result => {
        if (result) {
          if (action==='DEACTIVATE'){
            this.sharedService.deactivateEntity(this.dialogEntity)
          }
          
          else if (action==='DECLINE'){
            this.sharedService.declineEntity(this.dialogEntity)
          }
          else if (action==='SUSPEND'){
            this.sharedService.suspendEntity(this.dialogEntity)
          }
          else{
            this.sharedService.activateEntity(this.dialogEntity)
          }
          
          this.dialogRef.close();
        } else {
          this.dialogRef.close();
        }
      });
    
  }

  onUpdateClick(action: any, dialogText: any): void {
    if (this.dialogEntity.id) {
      const dialogRefConf = this.dialog.open(ConfirmationDialogComponent, {
        width: '500px',
        data: { text: "DIALOG." + dialogText, emitter: this.sharedService.updateEntity, obj: this.dialogEntity, action: action }
      });
      dialogRefConf.afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close();
        }
      });
    }
    else {
      this.sharedService.updateEntity(this.dialogEntity)
      this.dialogRef.close();
    }
  }

  onVisitStatusChange(dialgoText: String, action: string, apiResource: String): void {
    const dialogRefConf = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { text: dialgoText, obj: this.dialogEntity, action: action }
    });
    dialogRefConf.afterClosed().subscribe(result => {
      if (result) {
        this.sharedService.updateVisit({ patch_state: true, id: this.dialogEntity.id, status: apiResource })
        this.dialogRef.close();
      }
    });
  }

}
