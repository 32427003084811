import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { debounceTime, distinctUntilChanged, find, tap } from 'rxjs/operators';
import { fromEvent, merge } from 'rxjs';
import { UserStatusName } from 'src/app/entities/util';
import { TasDataSource } from 'src/app/services/tasGovTable.datasource';

import { SharedService } from 'src/app/services/sharedService';
import { ProcessTypeService } from 'src/app/services/proces-type.service';
import { Router } from '@angular/router';
import { ProcessType } from 'src/app/entities/processType';
import { YearService } from 'src/app/services/year.service';

@Component({
  selector: 'tas-gov-process',
  templateUrl: './process-type.component.html',
  styleUrls: ['./process-type.component.css']
})
export class ProcessTypeComponent implements OnInit, AfterViewInit {
 
  filterObject: {};
  displayedColumns: string[] = ['name', 'key', 'year', 'active', 'version'];
  dataSource: TasDataSource;
  statusNames = UserStatusName
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input_search', { static: false }) input: ElementRef;
  formYears = ["2017","2018","2019","2020"]
  newYear: number;
  errorAddYear: string;
  constructor(private processTypeService: ProcessTypeService, public dialog: MatDialog, public sharedService: SharedService, 
    public yearService:YearService, public router: Router) {
  }

  ngOnInit() {
    this.dataSource = new TasDataSource(this.processTypeService)
    this.getYears()
    
  }

  ngAfterViewInit() {

    setTimeout(() => {
      this.dataSource.loadData('process-types', '{}', 'asc', 'name', 0, this.paginator.pageSize, this.filterObject);

    }, 0)

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadProcessTypeTable();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadProcessTypeTable())
      )
      .subscribe();

  }

  loadProcessTypeTable() {
    this.dataSource.loadData('process-types',
      this.input.nativeElement.value,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize, this.filterObject);
  }

   openProcessTypeDialog(processType): void {
     if (processType){
      this.router.navigate(['process_type_add_edit'], { queryParams: { id: processType._id }});
     }
     
     else{
      this.router.navigate(['process_type_add_edit']);
     }
   }

 addYear(){
  var findYear = this.formYears.find(elem => elem['year'] == this.newYear)
  if (findYear==undefined){
    var year = {"year":this.newYear+"", "isActive":true, "visible":true}
    this.yearService.updateResource("year/add",year).subscribe(val=>{
      this.getYears()
      this.newYear=null
    })
  }
  else{
    this.errorAddYear = "YEAR_EXISTS"
  }
  
 }
 setActive(val,year){
  var yearVal = {"id":year, "isActive":val.checked}
  this.yearService.patchResource("year",yearVal).subscribe(val=>{
   
  })
 }
 setVisible(val,year){
  var yearVal = {"id":year, "visible":val.checked}
  this.yearService.patchResource("year",yearVal).subscribe(val=>{
   
  })
 }
 removeYear(id){
  var yearVal = {"id":id}
  this.yearService.deleteResource("year",yearVal).subscribe(val=>{
    this.getYears()
  })
 }

 getYears(){
  this.yearService.getList("year").subscribe(val=>{
    this.formYears = val
  });
 }

}

