import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge } from 'rxjs';
import { UserStatusName } from 'src/app/entities/util';
import { TasDataSource } from 'src/app/services/tasGovTable.datasource';
import { UserService } from 'src/app/services/user.service';
import { UserDialogComponent } from '../dialogs/user-dialog/user-dialog.component';
import { SharedService } from 'src/app/services/sharedService';

@Component({
  selector: 'tas-gov-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit, AfterViewInit, OnDestroy {


  subscriptionUpdateUser: any;
  subscriptionDeactivateUser: any;
  subscriptionActivateUser: any;
  filterObject: {};
  displayedColumns: string[] = ['username', 'name', 'surname', 'role', 'email', 'status'];
  dataSource: TasDataSource;
  statusNames = UserStatusName
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input_search', { static: false }) input: ElementRef;

  constructor(private userService: UserService, public dialog: MatDialog, public sharedService: SharedService) {
  }

  ngOnInit() {
    this.dataSource = new TasDataSource(this.userService)
    this.subscriptionUpdateUser = this.sharedService.updateUserEmmitter.subscribe(val => {
      if (val) {
        this.userService.updateResource('user', val).subscribe(res => {
          this.loadUserTable();
        })
      }
    })
    this.subscriptionDeactivateUser = this.sharedService.deactivateUserEmmitter.subscribe(val => {
      if (val) {
        this.userService.deactivateResource('user', val).subscribe(res => {
          this.loadUserTable();
        })
      }
    })

    this.subscriptionActivateUser = this.sharedService.activateUserEmmitter.subscribe(val => {
      if (val) {
        this.userService.activateResource('user', val).subscribe(res => {
          this.loadUserTable();
        })
      }
    })
  }

  ngAfterViewInit() {

    setTimeout(() => {
      this.dataSource.loadData('user', '', 'asc', 'name', 0, this.paginator.pageSize, this.filterObject);

    }, 0)

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadUserTable();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadUserTable())
      )
      .subscribe();

  }

  loadUserTable() {
    this.dataSource.loadData('user',
      this.input.nativeElement.value,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize, this.filterObject);
  }

  openUserDialog(user): void {
    const dialogRef = this.dialog.open(UserDialogComponent, {
      width: '600px', panelClass: 'custom-dialog-container',
      data: user
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  ngOnDestroy() {
    if (this.subscriptionUpdateUser) this.subscriptionUpdateUser.unsubscribe();
    if (this.subscriptionDeactivateUser) this.subscriptionDeactivateUser.unsubscribe();
    if (this.subscriptionActivateUser) this.subscriptionActivateUser.unsubscribe();
  }

}

