import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from './services/sharedService';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'tas-gov-web';
  isLoggedIn = false;
  lang: string;

  constructor(public translate: TranslateService, private router: Router, public sharedService: SharedService, public authService: AuthService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('fr');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.setLang(localStorage.getItem("default_lan") ? localStorage.getItem("default_lan") : 'fr')
    authService.loggedIn.subscribe(val => {
      this.isLoggedIn = val;
    })
  }
  ngOnInit() {
    this.authService.loggedIn.subscribe(val => {
      this.isLoggedIn = val;
    })
  }

  setLang(lang) {
    this.sharedService.setLan(lang)
    this.lang = lang
    this.translate.use(lang);
  }

}
