import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Role } from 'src/app/entities/role';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  notifParam: any;
  constructor(public router: Router, public authService: AuthService, public route: ActivatedRoute) { 

  }

  ngOnInit() {
    this.route.queryParamMap.subscribe(q => {
      this.notifParam = q['params'].notifications
    });
    var decodedToken = this.authService.getDecodedToken()
    if (decodedToken) {
      if (Role.ADMIN === Role[decodedToken.role + '']) {
        this.router.navigate(['user_management']);
      } else if (Role.USER === Role[decodedToken.role + '']) {
        if (this.notifParam) { this.router.navigate(['tax_payers'], { queryParams: { notifications: true } }) }
        else {
          this.router.navigate(['tax_payers'])
        }
      } else {
        this.router.navigate(['badge-issuer']);
      }
    }

  }

}


 
