const AdminMenu =  [
    ["/user_management", 'ADMIN-TOP-MENU.USER_MANAGEMENT'],
    //["/reporting", 'ADMIN-TOP-MENU.REPORTING'],
    ["/tax_payers", 'USER-TOP-MENU.TAX-PAYERS'],
    ["/company_representatives", 'ADMIN-TOP-MENU.COMPANY_REPRESENTATIVE'],
    ["/declarations", 'USER-TOP-MENU.DECLARATIONS'],
    ["/statistics", 'USER-TOP-MENU.STATISTICS'],
    ["/formcreation_foradmin525", 'USER-TOP-MENU.FORM_CREATOR'],
    ["/admin", 'ADMIN-TOP-MENU.ADMIN'],
];

const UserMenu = [
    ["/tax_payers", 'USER-TOP-MENU.TAX-PAYERS'],
    ["/declarations", 'USER-TOP-MENU.DECLARATIONS'],
    ["/statistics", 'USER-TOP-MENU.STATISTICS'],
  //  ["/tax_division", 'ADMIN-TOP-MENU.TAX_DIVISION'],
   //["/settings", 'USER-TOP-MENU.SETTINGS']
];

const DirectorMenu = [
    ["/badge-issuer", 'BADGE-ISSUER-TOP-MENU.VISITS']    
];

const CommonMenu = [
    ["/settings", 'COMMON-TOP-MENU.SETTINGS'],
    ["/help", 'COMMON-TOP-MENU.HELP']     
];

const MenuCategories = new Map<string, string[][]>([
    ["ADMIN", AdminMenu],
    ["USER", UserMenu],
    ["DIRECTOR", DirectorMenu],
    ["COMMON", CommonMenu]
]);

export { MenuCategories }