import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators'
import { SharedService } from './sharedService';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient, private sharedService: SharedService) { }

  getReportPage(key: String, stage: String) {
    let report$ = this.http.get<any>(this.sharedService.apiUrl('report/') + key + "/" + stage)
    return report$;
  }

  getPages(key: String) {
    let reportPageList$ = this.http.get<any>(this.sharedService.apiUrl('report/') + key)
    return reportPageList$;
  }

  getReportsList() {
    let reportsList$ = this.http.get<any>(this.sharedService.apiUrl('report/'))
    return reportsList$;
  }

  updateReportPage(page){
    return this.http.put<any>(this.sharedService.apiUrl('report/edit'), page, httpOptions).pipe(
      tap((page: any) => this.log(`page edited w/ id=${page.stageId}`)),
      catchError(this.handleError<any>('edit'))
    );
  }

  generateExcelMapping(repId){
    return this.http.put<any>(this.sharedService.apiUrl('report/gen-excel-mapping'), {id:repId}, httpOptions).pipe(
      tap((page: any) => this.log(`page edited w/ id=${page.stageId}`)),
      catchError(this.handleError<any>('edit'))
    );
  }

  addReportPage(page){
    return this.http.put<any>(this.sharedService.apiUrl('report/add'), page, httpOptions).pipe(
      tap((page: any) => this.log(`page edited w/ id=${page.stageId}`)),
      catchError(this.handleError<any>('edit'))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    //this.messageService.add('HeroService: ' + message);
  }
}
