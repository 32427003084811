import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuCategories } from '../top-menu/menuItems';
import { Role } from 'src/app/entities/role';
import { RoleName } from 'src/app/entities/util';

@Component({
  selector: 'tas-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public username: string;
  public password: string;
  public error: string;
  roleNames = RoleName
  notifParam: any;
  constructor(public authService: AuthService, public configService: ConfigService, public jwtHelper: JwtHelperService, public router: Router, private route: ActivatedRoute) { }

  ngOnInit() {



    this.configService.getJSON().subscribe(data => {
      localStorage.setItem("serverPath", data["serverPath"]);

      const token = this.jwtHelper.tokenGetter()
      if (token && token !== 'undefined') {
        const decodedToken = this.jwtHelper.decodeToken(token);
        var routePath = MenuCategories.get(decodedToken.role)[0][0]
        this.router.navigate([routePath]);
      }
      this.route.queryParamMap.subscribe(q => {
        this.notifParam = q['params'].notifications
      });

    });
  }

  login(username, password) {
    this.authService.login(username.value, password.value).subscribe(result => {
      this.authService.setLoginStatus(result)
      this.authService.getUserRole$().subscribe(role => {
        if (role === this.roleNames.get(Role.ADMIN)) {
          this.router.navigate(['user_management']);
        } else if (role === this.roleNames.get(Role.USER)) {
          if (this.notifParam) { this.router.navigate(['tax_payers'], { queryParams: { notifications: true } }) }
          else {
            this.router.navigate(['tax_payers'])
          }
        } else {
          this.router.navigate(['badge-issuer']);
        }
      })
    },
      err => {
        if (err.status === 0) {
          this.error = 'ERRORS.CONNECTION_FAILED'
        }
        if (err.status === 401) {
          this.error = 'ERRORS.UNAUTHORIZED';
        }
      })
  }


}
