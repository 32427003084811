import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorpageComponent } from './components/errorpage/errorpage.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { AuthGuard } from './guards/auth.guard';
import { UserComponent } from './components/user/user.component';
import { TaxPayersComponent } from './components/tax-payers/tax-payers.component';
import { TxDeclarationsComponent } from './components/tx-declarations/tx-declarations.component';
import { ProcessViewComponent } from './components/process/process-view/process-view.component';
import { ProcessStepComponent } from './components/process/process-step/process-step.component';
import { FormCreatorComponent } from './components/form-creation/form-creator/form-creator.component';
import { ProcessTypeComponent } from './components/form-creation/process-type/process-type.component';
import { ProcessAddEditComponent } from './components/form-creation/process-type/process-add-edit/process-add-edit.component';
import { DeclarationsComponent } from './components/declarations/declarations.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { ReportingComponent } from './components/declarations/reporting/reporting.component';
import { SettingsComponent } from './components/settings/settings.component';
import { TaxDivisionComponent } from './components/tax-division/tax-division.component';
import { StatDetailsComponent } from './components/statistics/stat-details/stat-details.component';
import { CompanyRepresentativeComponent } from './components/company-representative/company-representative.component';
import { HomepageAdminComponent } from './components/homepage-admin/homepage-admin.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'user_management', component: UserComponent, canActivate: [AuthGuard] },
  { path: 'tax_payers', component: TaxPayersComponent, canActivate: [AuthGuard] },
  { path: 'tx_declarations', component: TxDeclarationsComponent, canActivate: [AuthGuard] },
  { path: 'process/:id/:step', component: ProcessStepComponent,  canActivate: [AuthGuard] },
  { path: 'process/:id', component: ProcessViewComponent,  canActivate: [AuthGuard]},
  { path: 'declarations', component: DeclarationsComponent,  canActivate: [AuthGuard]},
  { path: 'declaration_reporting', component: ReportingComponent,  canActivate: [AuthGuard]},
  { path: 'company_representatives', component: CompanyRepresentativeComponent,  canActivate: [AuthGuard]},
  { path: 'statistics', component: StatisticsComponent, canActivate: [AuthGuard]},
  { path: 'stat_details', component: StatDetailsComponent, canActivate: [AuthGuard]},
  { path: 'edit_form', component: FormCreatorComponent, canActivate: [AuthGuard]},
  { path: 'edit_form/:year/:reportId/:stageId', component: FormCreatorComponent, canActivate: [AuthGuard]},
  { path: 'formcreation_foradmin525', component: ProcessTypeComponent, canActivate: [AuthGuard]},
  { path: 'process_type_add_edit', component: ProcessAddEditComponent, canActivate: [AuthGuard]},
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard]},
  { path: 'tax_division', component: TaxDivisionComponent, canActivate: [AuthGuard]},
  { path: 'admin', component: HomepageAdminComponent, canActivate: [AuthGuard]},
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'error/:error', component: ErrorpageComponent},
  { path: '404', component: ErrorpageComponent },
  { path: '**', redirectTo: '/404' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
