export class ProcessType {
    public id: number;
    public name: string;
    public key: string;
    public version: string;
    public active: Boolean;  
    public external: Boolean;
    public year: string;
    public stages: any[];

  
  }  