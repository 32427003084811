import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';

import { map, catchError, share } from 'rxjs/operators';
import { SharedService } from './sharedService';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Role } from '../entities/role';


@Injectable()
export class AuthService {
  private username$ = new BehaviorSubject<string>(undefined);
  private userRole$ = new BehaviorSubject<string>(undefined);
  accessToken: string
  constructor(private http: HttpClient, private sharedService: SharedService, private decoder: JwtHelperService) {  this.accessToken = sharedService.getAccessToken() }
 
  login(username: string, password: string): Observable<boolean> {
    return this.http.post<{ token: string }>(this.sharedService.apiUrlLogin('api/auth'), { username: username, password: password }, {headers:{'Content-Type': 'application/json'}})
      .pipe(
        map(result => {
        
         // if (result['statusCode'] === 200) {
            localStorage.setItem(this.accessToken, result.token);
            var decodedToken = this.getDecodedToken()
            this.setUsername(username)
            this.setUserRole$(decodedToken['role'])
            return true;
        //  }
        }), catchError(
          
          (err) => throwError(err)
          
          )
      );
  }



  logout() {
    localStorage.removeItem(this.accessToken)
    localStorage.removeItem('username')
    localStorage.removeItem('userrole')
    this.setLoginStatus(false)
  }


  @Output() loggedIn: EventEmitter<boolean> = new EventEmitter();
  setLoginStatus(obj) {
    this.loggedIn.emit(obj)
  }

  setUsername(obj) {
    this.username$.next(obj);
  }

  setUserRole$(obj) {
    this.userRole$.next(obj);
  }

  getUsername$(): Observable<any> {
    return this.username$.asObservable();
  }

  getUserRole$(): Observable<any> {
    return this.userRole$.asObservable();
  }

  getDecodedToken(){
    return this.decoder.decodeToken(this.decoder.tokenGetter())
  }

 


}
