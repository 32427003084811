import { Injectable, Output, EventEmitter } from "@angular/core";
import { environment } from "../../environments/environment";
import { Observable, Subject } from "rxjs";
import { User } from '../entities/user';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ConfigService } from './config.service';
import { CompanyRepresentative } from '../entities/companyRepresentative';
import { TaxPayer } from '../entities/taxPayer';

@Injectable()
export class SharedService {
  private lan$ = new Subject<any>();
  private serverPath:string;
  constructor(public configService: ConfigService) {
    this.serverPath = this.configService.getSavedServerPath();
  }

  apiUrl(pathToAPI: string, parameters = ''): string {
    return this.configService.getSavedServerPath()+"/api" +  '/' + pathToAPI + '/' + parameters;
  }

  apiUrlLogin(pathToAPI: string, parameters = ''): string {
    return environment.apihost + '/' + pathToAPI
  }


  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);
      return Observable.throw(error.error || 'Server error');
    };
  }

  getAccessToken() {
    return environment.accessToken;
  }


  log(message: string) {
    //this.messageService.add('HeroService: ' + message);
  }

  getLan$(): Observable<any> {
    return this.lan$.asObservable();
  }

  getLan() {
    return localStorage.getItem('default_lan');
  }

  setLan(lan) {
    localStorage.setItem('default_lan', lan);
    this.lan$.next(lan);
  }

  
  @Output() deactivateUserEmmitter: EventEmitter<User> = new EventEmitter();
  
  deactivateEntity(obj) {
    if (obj instanceof User || obj['class'] === 'USER') {
      this.deactivateUserEmmitter.emit(obj)
    }

  }

  @Output() activateUserEmmitter: EventEmitter<User> = new EventEmitter();
  
  @Output() activateCompRepEmmitter: EventEmitter<User> = new EventEmitter();
  @Output() declineCompRepEmmitter: EventEmitter<User> = new EventEmitter();
  @Output() suspendCompRepEmmitter: EventEmitter<User> = new EventEmitter();
  @Output() updateTaxPayerEmmitter: EventEmitter<User> = new EventEmitter();
  
  activateEntity(obj) {
    if (obj instanceof User || obj['class'] === 'USER') {
      this.activateUserEmmitter.emit(obj)
    }
    if (obj instanceof CompanyRepresentative || obj['class'] === 'COMPANY_REPRESENTATIVE') {
      obj['status'] = 1
      this.updateCompanyRepresentativeEmmitter.emit(obj)
     }
   
  }   
  
  declineEntity(obj) {
    if (obj instanceof CompanyRepresentative || obj['class'] === 'COMPANY_REPRESENTATIVE') {
      obj['status'] = 2
      this.updateCompanyRepresentativeEmmitter.emit(obj)
     }
  } 

  suspendEntity(obj) {
    obj['status'] = 3
    if (obj instanceof CompanyRepresentative || obj['class'] === 'COMPANY_REPRESENTATIVE') {
      this.updateCompanyRepresentativeEmmitter.emit(obj)
     }
     
  } 


  @Output() updateUserEmmitter: EventEmitter<User> = new EventEmitter();
  @Output() updateCompanyRepresentativeEmmitter: EventEmitter<User> = new EventEmitter();
  updateEntity(obj) {
    if (obj instanceof User || obj['class'] === 'USER') {
     this.updateUserEmmitter.emit(obj)
    }
    if (obj instanceof CompanyRepresentative || obj['class'] === 'COMPANY_REPRESENTATIVE') {
      this.updateCompanyRepresentativeEmmitter.emit(obj)
     }

     if (obj instanceof TaxPayer || obj['class'] === 'TAX-PAYER') {
      this.updateTaxPayerEmmitter.emit(obj)
    }

  }

  @Output() updateVisitEmmitter: EventEmitter<any> = new EventEmitter();
  updateVisit(obj) {
    this.updateVisitEmmitter.emit(obj)
  }

  @Output() notifyStartedVisitEmmiter: EventEmitter<any> = new EventEmitter();
  notifyStartedVisit() {
    this.notifyStartedVisitEmmiter.emit()
  }
  @Output() notifyAcceptedVisitEmmiter: EventEmitter<any> = new EventEmitter();
  notifyAcceptedVisit() {
    this.notifyAcceptedVisitEmmiter.emit()
  }
  @Output() notifyRejectedVisitEmmiter: EventEmitter<any> = new EventEmitter();
  notifyRejectedVisit() {
    this.notifyRejectedVisitEmmiter.emit()
  }

  @Output() notifyEndedVisitEmmiter: EventEmitter<any> = new EventEmitter();
  notifyEndedVisit() {
    this.notifyEndedVisitEmmiter.emit()
  }
  

  @Output() pendingVisitNumberEmmitter: EventEmitter<boolean> = new EventEmitter();
  setPendingVisitNumber(obj) {
    this.pendingVisitNumberEmmitter.emit(obj)
  }
 
  @Output() visitorBlacklistEmmiter: EventEmitter<boolean> = new EventEmitter();
  setVisitorBlacklist(obj) {
    this.visitorBlacklistEmmiter.emit(obj)
  }

  @Output() notifyTableReloadEmmiter: EventEmitter<any> = new EventEmitter();
  notifyTableReload() {
    this.notifyTableReloadEmmiter.emit()
  }


  @Output() notifyTableLoadEmmiter: EventEmitter<any> = new EventEmitter();
  notifyLoadTable(obj){
    this.notifyTableLoadEmmiter.emit(obj)
  }

  @Output() deleteBlock: EventEmitter<number> = new EventEmitter();
  updateDeleteBlock(ind) {
    this.deleteBlock.emit(ind)
  }
  @Output() renameBlock: EventEmitter<any> = new EventEmitter();
  updateRenameBlock(name, ind) {
    this.renameBlock.emit({ name: name, ind: ind })
  }
  @Output() addRow: EventEmitter<any> = new EventEmitter();
  updateAddRow(blockIndx, row) {
    this.addRow.emit({ blockIndx: blockIndx, row: row })
  }

  @Output() updateRow: EventEmitter<any> = new EventEmitter();
  updateUpdateRow(blockIndx, row) {
    this.updateRow.emit({ blockIndx: blockIndx, row: row })
  }

  @Output() moveRow: EventEmitter<any> = new EventEmitter();
  updateMoveRow(blockIndx, row, direction) {
    this.moveRow.emit({ blockIndx: blockIndx, row: row, direction: direction })
  }

  @Output() addColumn: EventEmitter<any> = new EventEmitter();
  updateAddColumn(blockIndx, column) {
    this.addColumn.emit({ blockIndx: blockIndx, column: column })
  }

  @Output() deleteColumnEmitter: EventEmitter<any> = new EventEmitter();
  deleteColumn(blockIndx, column) {
    this.deleteColumnEmitter.emit({ blockIndx: blockIndx, column: column })
  }

  @Output() updateColumnEmmitter: EventEmitter<any> = new EventEmitter();
  updateColumn(blockIndx, column, colIndex) {
    this.updateColumnEmmitter.emit({ blockIndx: blockIndx, column: column, colIndex:  colIndex})
  }

  @Output() deleteRowEmitter: EventEmitter<any> = new EventEmitter();
  deleteRow(blockIndx, row) {
    this.deleteRowEmitter.emit({ blockIndx: blockIndx, row: row })
  }




  @Output() deleteFormEmmiter: EventEmitter<any> = new EventEmitter();
  deleteForm(obj) {
      this.deleteFormEmmiter.emit(obj)
  }

  @Output() formulaKeyEmmiter: EventEmitter<any> = new EventEmitter();
  
  emitFormulaKey(obj) {
      this.formulaKeyEmmiter.emit(obj)
  } 

  @Output() confirmDialogEmitter: EventEmitter<boolean> = new EventEmitter();
  confirmDialogAction(obj){
    this.confirmDialogEmitter.emit(obj)
  }



}
